var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";
class src_default extends Controller {
  initialize() {
    this.onUpdate = this.onUpdate.bind(this);
  }
  connect() {
    this.sortable = new Sortable(this.element, __spreadValues(__spreadValues({}, this.defaultOptions), this.options));
  }
  disconnect() {
    this.sortable.destroy();
    this.sortable = void 0;
  }
  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl)
      return;
    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;
    const data = new FormData();
    data.append(param, newIndex + 1);
    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue });
  }
  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || void 0,
      onUpdate: this.onUpdate
    };
  }
  get defaultOptions() {
    return {};
  }
}
src_default.values = {
  resourceName: String,
  paramName: {
    type: String,
    default: "position"
  },
  responseKind: {
    type: String,
    default: "html"
  },
  animation: Number,
  handle: String
};
export { src_default as default };
